body {
    background-image: url('/img/bg-texture.png');
}

.table-hover > tbody > tr:hover {
    background-color: lighten($masc-primary-gold, 40%);
}

.navbar {
    background-image: linear-gradient(to bottom right, darken(red, 10%), darken(red, 20%));
    text-shadow: 1px 1px 1px black;
    border-radius: 0px;
    box-shadow: 0px 1px 1px black;
    border-bottom: 2px solid $masc-primary-gold;
    border-left-width:0px;
    border-right-width:0px;
}
.navbar-brand {
    font-size: 2em;
    background-image:url('/img/logo-medium-transparent.png');
    background-repeat: no-repeat;
    background-position: left;
}

.panel-heading {
    background-image: linear-gradient(to bottom right, darken(red, 10%), darken(red, 20%));
    font-weight: bold;
    color: white !important;
    text-shadow: 1px 1px 5px black;
}

.btn-primary {
    border-width: 2px;
}

footer.footer {
    text-align: center;
    padding:30px;
}
