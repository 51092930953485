@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.4.0/css/font-awesome.min.css");
// @import "./_pictos-redux-part-1-lines.css";
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

$masc-primary-red: darken(#ff0000, 15%);
$masc-primary-gold: #ffb200;

$font-family-sans-serif: "Open Sans";
$navbar-default-bg: darken(#ff0000, 15%);
$navbar-default-color: #ffffff;
$navbar-default-link-color: #ffffff;
$navbar-height: 70px;
$navbar-default-link-hover-bg: darken(red, 20%);
$navbar-default-link-hover-color: #ffffff;
$panel-border-radius: 10px;
$btn-primary-bg: $masc-primary-gold;
$btn-primary-color: black;
$btn-primary-border: darken($masc-primary-gold, 5%);
$table-bg-hover: lighten($masc-primary-gold, 50%);

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "./general.scss";
@import "./registration.scss";
@import "./invoice.scss";
@import "./login.scss";
@import "./school.scss";
@import "./admin.scss";
@import "./transitions.scss";

[v-cloak] {
    display: none;
}