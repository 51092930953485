.login-panel {
    text-align: center;
    h1 {
        font-size:2em;
    }
    h2 {
        font-size:1.5em;
    }
    form {
        text-align: left;
    }
    .form-container {
        padding:20px;
    }
}

#logo {
    img {
        width:200px;
    }
}