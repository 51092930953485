@media print {
    .container, .invoice-container {
        padding: 0;
        margin: 0;
    }
}

#invoice {
    background-color: white;
}

.invoice-directions {
}

.invoice-container {
    width:800px;
    margin:auto;
    text-align: center;
    background-color: white;
    padding:40px;
    font-family: "Arial";

    .invoice-info {
        font-size:16px;
        padding:5px; 
        text-align:left;
        border-bottom-width: 0px !important;
    }

    .box {
        border: 1px solid black;
        margin-bottom: 5px;
        font-size:12px;
    }

    .school-box  {
        font-size:12px;
        text-align: left;
        padding:3px;
        padding-left:10px;
    }

    .totals-box {
        padding:5px;
    }

    .advisor-box {
    }

    .total-due {
        font-size:20px;
    }

    .signature-field {
        margin: auto;
        margin-top:50px;
        margin-bottom: 5px;
        font-size: 10px;
        text-align: left;
    }

    .signature-field td {
        border-top:1px solid black;
    }

    .attendees-table {
        width:100%;
        font-size: 12px;
    }

    .attendees-table th {
        text-align: center;
    }
}
