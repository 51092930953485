.event-title {
    font-size: 2em;
    padding-bottom: 5px;
}

@media (min-width: $screen-md-max) {
    .event-info {
        padding-left: 40px;
        border-left: 1px solid lighten(gray, 30%);
        margin-left: -5px;
    }
}

@media (max-width: $screen-md-max) {
    .event-info {
        padding-top: 15px;
    }
}

.event-info {
    line-height: 1.7em;
}