.school-name {
    font-size:1.5em;
}

.school-address {
    font-size:1.2em;
}

.school-votes-container {
    padding:0px;
    text-align: center;
    width:80px;
    height:80px;
    margin:auto;
    border:2px solid black;
    label {
        font-weight: bold;
    }
    div.votes-number {
        font-size: 3em;
        font-weight: bold;
        line-height: 28px;
    }
    a {
        font-size: 0.8em;
    }
    #votes-help-modal * {
        text-align: left;
    }
}

.school-panel, .votes-panel {
    height:155px;
}