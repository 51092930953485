/* always present */
.expand-transition {
  transition: all 1s ease;
  opacity: 1;
}

/* .expand-enter defines the starting state for entering */
/* .expand-leave defines the ending state for leaving */
.expand-enter, .expand-leave {
  opacity: 0;
}