nav.admin-nav {
    height:40px;
    margin-top:-20px;
    margin-bottom:20px;
    background-color: white;
    color: black;
    line-height: 40px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

@media (min-width: 1200px) {    
    .admin-nav {
        width: 1170px;
    }
}

@media (min-width: 992px) {
    .admin-nav {
        width: 970px;
    }
}

@media (min-width: 768px) {
    .admin-nav {
        width: 750px;
    }
}
